import { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import useArticleSummaries from './useArticleSummaries'

const useRelatedPosts = (articleData, postPerPage) => {
	const articleSummaries = useArticleSummaries()
	const [relatedPosts, setRelatedPosts] = useState([])

	useEffect(() => {
		const r = articleSummaries.filter(
			a => {
				if(a.title === articleData.title) { return false; }

				if(a.category.name !== articleData.category.name) { 
					return false;
				}
				
				return true;
			}
		);

		setRelatedPosts(r)
	}, [])

	
	return relatedPosts
}

export default useRelatedPosts