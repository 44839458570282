import React from 'react'
import styled from 'styled-components'
//import Card from './Card'
import { RelatedPosts } from './RelatedPosts'
import { SnsShareButtons } from './SnsShareButtons'
//import Tags from './Tags'
import Tag from './Tag'

const StyledArticle = styled.article`
	
	& .article-header {
		margin-bottom: 2em;

		/*&-image {
			background-color: #333;
			
			overflow: hidden;
			padding-top: 56.25%;
			position: relative;

			& img {
				position: absolute;
				top: 0;
			}
		}*/
		& img {
			display: block;
			height: auto;
			max-width: 100%;
		}
	}

	& .article-title {
		font-size: 2em;
		//margin-bottom: 1em;
	}

	& .article-tags {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 1em;

		& .article-tag {
			background-color: var(--tag-bg-color);
			border-radius: 3px;			
			font-size: .8em;			
			margin-right: .5em;
			margin-bottom: .5em;
			user-select: none;

			> a {
				color: var(--tag-color);
				display: block;
				//padding: .3em 1em;
				text-decoration: none;
			}
		}
	}

	& .article-category {
		background-color: var(--accent-color);
		border-radius: 3px;
		display: inline-block;
		margin-bottom: 1.2em;

		> a {			
			color: white;
			display: block;
			padding: .3em 1em;
			text-decoration: none;
		}
	}

	& .article-date {
		color: #888;
		font-size: .8em;
		text-align: right;
		margin-bottom: 1em;
	}
	
	& .article-content p {
		line-height: 2;
		margin: 1em 0;
		word-break: break-word;
	}

	& .article-content {
		//max-width: 100%;
		
		margin: 3em 0;

		& img,
		& video {
			max-width: 100%;
			height: auto;
			object-fit: contain;
		}
	}

	& .article-content strong {
		color: var(--brand-color);
	}

	& .article-content h2 {
		
		font-size: 2em;
		padding-bottom: .3em;
		margin-top: 2em;
		margin-bottom: 1em;
	}

	& .article-content h3 {
		font-size: 1.5em;
		
		margin-top: 2em;
	}

	& .article-share {
		//margin-top: 4em;
	}

	& .related-posts {
		//margin-top: 4em;
	}
`

export const Article = ({ className, articleData, relatedPosts }) => {
	const publishedDate = new Date(articleData.publishedAt);
	const year = publishedDate.getFullYear().toString();
	const month = (publishedDate.getMonth() + 1).toString().padStart(2, '0');
	const date = publishedDate
		.getDate()
		.toString()
		.padStart(2, '0');
	const publishedDateStr = `${year}/${month}/${date}`;

	return (
		<StyledArticle className={className} publishedDateString={publishedDateStr}>
			<header className="article-header">
				<Tag 
					className={`article-category category-${articleData.category.name}`}
					name={articleData.category.name}
					path={`/category/${encodeURIComponent(articleData.category.name)}`} 
				/>
				<h1 className="article-title">{articleData.title}</h1>
				
				<div className="article-date">{articleData.publishedAt}</div>
			    <div className="article-header-image">
			    	{(articleData.headerImage) &&
			        	<img src={articleData.headerImage.file.url} alt="eye catch" />
			    	}
			    </div>
			</header>
			<section className="article-content" dangerouslySetInnerHTML={{__html: `<div>${articleData.content}</div>`}} />
			<footer className="article-footer">
				<div className="article-tags">
					{articleData.tags.map((tag, index) => (
						<Tag
							className={`article-tag tag-${tag.name}`}
							name={tag.name}
							path={`/tag/${encodeURIComponent(tag.name)}`}
							key={index}
						/>
					))}
				</div>
				<div css={`margin-top: 5em;`}>
					<h3 css={`text-align: center;`}>この記事をシェアする</h3>
					<SnsShareButtons className="sns-share-buttons" articleData={articleData} />
				</div>
				<div css={`margin-top: 5em;`}>
					<h3 css={`text-align: center;`}>関連する記事</h3>
					<RelatedPosts className="related-posts" articleData={articleData} postsPerPage={6}/>
				</div>
			</footer>
		</StyledArticle>
	)
}
// https://github.com/gatsbyjs/gatsby/issues/11108
//export default Article