//import React, { useCallback } from 'react'
//import { Link, navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import {Card} from './Card'
//import Button from './Button'
import Tag from './Tag'

const StyledArticleSummary = styled(Card)`
	//border: 4px solid var(--text-color);
	position: relative;
	& .card-image {		
		//border-bottom: 4px solid var(--text-color);
	}

	& .card-body {
		padding-left: 0; 
		padding-right: 0; 
	}
	
	& .article-category {
		background-color: var(--accent-color);
		color: white;
		position: absolute;
		top: -2px;
		left: -2px;
		z-index: 2;
	}


	& .article-date {
		color: #888;
		font-size: .8em;
		//text-align: right;
	}
`

export const ArticleSummary = ({ className, articleSummary }) => {
	const card = {
				image: articleSummary.headerImage.file.url,
				title: articleSummary.title,
				anchor: `/${articleSummary.publishedAt}/${articleSummary.slug}`,
			}
	return (
		<StyledArticleSummary
			className={className}
			card={card}
		>
			<Tag 
				className={`article-category category-${articleSummary.category.name}`}
				name={articleSummary.category.name}
				path={`/category/${encodeURIComponent(articleSummary.category.name)}`} 
			/>
			<div className="article-date">{articleSummary.publishedAt}</div>
		</StyledArticleSummary>
)
}
//export default ArticleSummary