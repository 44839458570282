import { useState, useCallback } from 'react'

const useSearchform = () => {
	const [textValue, setTextValue] = useState("")

	const handleChange = useCallback(e => {
		console.log(e.target.value)
		setTextValue(e.target.value)
	}, [])

	return [textValue, handleChange]
}

export default useSearchform
