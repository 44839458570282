import React, { useEffect, useLayoutEffect } from 'react'
import styled from 'styled-components'
//import AdSense from 'react-adsense';
/*
export const Adsense = ({ client, slot }) => (
	<div>
		<AdSense.Google
			style={{ display: "block" }}
			client={client}
			slot={slot}
			format='auto'
			responsive="true"
		/>
	</div>
)
*/

const StyledAdsense = styled.div`
overflow: hidden;
	width: 100%;
`

export const Adsense = ({ className, currentPath, client, slot, format = "auto" }) => {
	 useEffect(() => {
	 	//console.log(currentPath)
	    if (window) {
	      window.adsbygoogle = window.adsbygoogle || []
	      window.adsbygoogle.push({})
	    }
	  }, [currentPath])
	 
	return (
		<StyledAdsense key={currentPath} className={className}>
			<ins
				className="adsbygoogle"
				style={{ display: "block" }}
				data-ad-client={client}
				data-ad-slot={slot}
				data-ad-format={format}
				data-full-width-responsive="false"
			/>
		</StyledAdsense>
	)
}
