import React from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/useSiteMetadata'
import { Button } from './Button'
import { Icon } from './Icon'
import getIcon from '../utils/getIcon'
import { faFacebook } from "@fortawesome/free-brands-svg-icons"

const StyledFacebookShareButton = styled(Button)`
border: none;
	padding: 0;
	& a {
		background-color: #3b5998;
		//border: 2px solid var(--text-color);

	}
`

export const FacebookShareButton = ({ className, articleData }) => {
	const { siteUrl } = useSiteMetadata()

	return (
		<StyledFacebookShareButton className={className}>
			<a href={`http://www.facebook.com/share.php?u=${encodeURIComponent(`${siteUrl}${articleData.pagePath}`)}`}
				rel="noopener noreferrer nofollow"
				target="_blank"
			>
				<Icon icon={faFacebook} />
			</a>
		</StyledFacebookShareButton>
	)
}
