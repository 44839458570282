import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledTag = styled.div`
	background-color: var(--tag-color);
	//border: 2px solid var(--text-color);
	border-radius: 3px;
	color: var(--text-color);
	font-size: .8em;
	padding: .3em 1em;
	cursor: pointer;
	text-align: center;
	use-select: none;
	
	& a {
	    color: inherit;
	    text-decoration: none;
	}
`

const Tag = ({ className, name, path, children }) => (
	<StyledTag className={className}>
		<Link to={path}>
			{name}
			{children}
		</Link>
	</StyledTag>
)

export default Tag