import React from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/useSiteMetadata'
import { Button } from './Button'
import { Icon } from './Icon'
import getIcon from '../utils/getIcon'
import { faGetPocket } from "@fortawesome/free-brands-svg-icons"

/*
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)
*/
const StyledPocketShareButton = styled(Button)`
border: none;
	padding: 0;
	& a {
		background-color: #d3505a;
		//border: 2px solid var(--text-color);
	}
`

export const PocketShareButton = ({ className, articleData }) => {
	const { siteUrl } = useSiteMetadata()

	return (
		<StyledPocketShareButton className={className}>
			<a href={`http://getpocket.com/edit?url=${encodeURIComponent(`${siteUrl}${articleData.pagePath}`)}&title=${articleData.title}`}
				rel="noopener noreferrer nofollow"
				target="_blank"
			>
				<Icon icon={faGetPocket} />
			</a>
		</StyledPocketShareButton>
	)
}
