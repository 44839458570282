import React from 'react'
import styled from 'styled-components'
import { TwitterShareButton } from './TwitterShareButton'
import { FacebookShareButton } from './FacebookShareButton'
import { HatenaShareButton } from './HatenaShareButton'
import { PocketShareButton } from './PocketShareButton'
import { LineShareButton } from './LineShareButton'
import { FeedlyShareButton } from './FeedlyShareButton'

const StyledSnsShareButtons = styled.div`
	display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1em;
  row-gap: 1em;


`

export const SnsShareButtons = ({ className, articleData }) => {
	return (
		<StyledSnsShareButtons className={className}>
			<TwitterShareButton className="twitter-share-button" articleData={articleData} />
			<FacebookShareButton className="facebook-share-button" articleData={articleData} />
			<HatenaShareButton className="hatena-share-button" articleData={articleData} />
			<PocketShareButton className="pocket-share-button" articleData={articleData} />
			<LineShareButton className="line-share-button" articleData={articleData} />
			<FeedlyShareButton className="feedly-share-button" articleData={articleData} />
		</StyledSnsShareButtons>
	)
}

//export default SnsShareButtons
