import React from 'react'
import styled from 'styled-components'
import { ArticleSummary } from './ArticleSummary'
//import { AutoPager } from './AutoPager'
//import { Button } from './Button'
//import useAutoPager from '../hooks/useAutoPager'

const StyledArticleSummaries = styled.div`
	& .article-summary {
		
	    //&:nth-of-type(n+${({ skip }) => skip}) {
	    //	display: none;
	    //}
	    
	}
`

// skip→現在の表示件数
const ArticleSummaries = ({ className, articleSummaries }) => {//skip }) => {
	//const [currentArticleSummaries, skip, limit, seeMore] = useAutoPager(articleSummaries, postsPerPage)///4)
	//console.log(articleSummaries)
	return (
		<>
			<StyledArticleSummaries className={className}>
				{	
		       		//articleSummaries.slice(0, skip)
		       		articleSummaries && articleSummaries.map((a, i) => (
		        		<ArticleSummary className="article-summary" key={i} articleSummary={a} />			   
		        	))
		        }
		    </StyledArticleSummaries>
		    
	    </>
	)
}

export default ArticleSummaries

/*
{
	    	articleSummaries.limit > 0 &&
	    		<div css={`text-align: center`}>
	    			<StyledButton className="more-see-button" onClick={articleSummaries.seeMore}>{`もっと見る（あと${articleSummaries.limit}記事）`}</StyledButton>
	    		</div>
	    }
	    */