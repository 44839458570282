import React from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/useSiteMetadata'
import { Button } from './Button'
import { Icon } from './Icon'
import getIcon from '../utils/getIcon'
import { faLine } from "@fortawesome/free-brands-svg-icons"

const StyledLineShareButton = styled(Button)`
border: none;
	padding: 0;
	& a {
		background-color: #00B900;
	//border: 2px solid var(--text-color);
	}
`

export const LineShareButton = ({ className, articleData }) => {
	const { siteUrl } = useSiteMetadata()
	
	//library.add(fabTwitter)

	return (
		<StyledLineShareButton className={className}>
			<a href={`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(`${siteUrl}${articleData.pagePath}`)}`}
			rel="noopener noreferrer nofollow"
			target="_blank"
			>
				<Icon icon={faLine} />
			</a>
		</StyledLineShareButton>
	)
}
