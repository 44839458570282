import { useStaticQuery, graphql } from 'gatsby'

const useArticleSummaries = () => {
	const { allContentfulBlogPost } = useStaticQuery(
		graphql`
			query ArticleSummariesQuery {
				allContentfulBlogPost(sort: {order: DESC, fields: publishedAt}) {
				    edges {
				      node {
				        slug
				        title
				        publishedAt(formatString: "YYYY/MM/DD")
				        headerImage {
				          file {
				            url
				          }
				        }
				        content {
				          content
				          childMarkdownRemark {
				            html
				          }
				        }
				        tags {
				          name
				        }
				        category {
				          name
				        }
				      }
				    }
				  }
			}
		`
	)

	const articleSummaries = allContentfulBlogPost.edges.map(edge => edge.node)

	return articleSummaries
}

export default useArticleSummaries