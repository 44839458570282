import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledCard = styled.div`
	background-color: var(--card-bg-color);
	border-radius: .25em;
	//color: var(--text-color);
	display: flex;
	flex-direction: column;
	height: 100%;

	& .card-body {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 1em;
	}

	& .card-image {
		background-color: #333;
		border-radius: .25em;//.25em .25em 0 0;
		overflow: hidden;
		padding-top: 56.25%;
		position: relative;

		& img {
			position: absolute;
			top: 0;
		}
	}

	& .card-title {
		font-size: 1em;
		flex: 1;
		
    	line-height: 1.428;
		margin-bottom: 1em;

		&-anchor {
			//color: var(--text-color);
			text-decoration: none;
		}
	}

	& .card-meta {
		font-size: .8em;
		text-align: right;
	}
`

export const Card = ({ className, card, children }) => (
	<StyledCard className={className}>
		<div className="card-image">
			{card.image &&
		        <img src={card.image} alt="eye catch" />     
	        }
		</div>
	    <div className="card-body">
			<h3 className="card-title">
				<Link className="card-title-anchor" to={card.anchor}>
					{card.title}
				</Link>
			</h3>
			{card.text &&
				<p class="card-text"></p>
			}
			<div className="card-meta">{children}</div>
		</div>
	</StyledCard>
)

//export default Card