import React from 'react'
import {Card} from './Card'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import useRelatedPosts from '../hooks/useRelatedPosts';

const StyledRelatedPosts = styled.div`
	& a {
		color: var(--text-color);
		text-decoration: none;
	}

	> div {
		display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1em;
  row-gap: 1em;
  		@media screen and (max-width: 780px) {
	    grid-template-columns: 1fr 1fr;
	  }
	}

	& .related-post {
		//border: 2px solid var(--text-color);
	border-radius: .25em;
	}
	
	& .card-image {		
			//border-bottom: 2px solid var(--text-color);
	}
	
	& .card-title {
		font-size: .8em;
		margin-bottom: 1em;
	}

	& .card-body {
		padding-left: 0;
		padding-right: 0;
	}

	& .related-post-date {
		color: #888;
		display: block;
		font-size: .6em;
		text-align: right;
	}
`

export const RelatedPosts = ({ articleData, postsPerPage, className }) => {
	const relatedPosts = useRelatedPosts(articleData, postsPerPage)

	return (
		<StyledRelatedPosts className={className}>
				{
					(relatedPosts.length > 0) ?
						<div>
							{relatedPosts.slice(0, postsPerPage).map((r, i) => (
								//<ArticleSummary className="article-summary" key={r.slug} articleSummary={r} />
								<Card 
									key={i}
									className="related-post"
									card={{
										image: r.headerImage.file.url,
										title: r.title,
										anchor: `/${r.publishedAt}/${r.slug}`,
									}}
								>
									<time className="related-post-date">{r.publishedAt}</time>
								</Card>
							))}
						</div>
					:
						<p>関連する記事は見つかりませんでした。</p>
				}
		</StyledRelatedPosts>
	);
}



//export default RelatedPosts;