import React, { useState, useEffect, useCallback } from 'react'
import { withPrefix } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Article } from '../components/Article'
import styled from 'styled-components'
//import Sidebar from '../components/Sidebar'
//import Card from '../components/Card'
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import { Header } from '../components/header'
import { Footer } from '../components/Footer'
import { Tags } from '../components/Tags'
import InputText from '../components/InputText'
//import FilteredArticleSummaries from '../components/FilteredArticleSummaries'
import { AutoPager } from '../components/AutoPager'
import ArticleSummaries from '../components/ArticleSummaries'

import { Page } from '../components/Page'
import { Adsense } from '../components/Adsense'
import { SiteTitle } from '../components/SiteTitle'
import { Copyright } from '../components/Copyright'
import { Author } from '../components/Author'
import { Contacts } from '../components/Contacts'
import { Categories } from '../components/Categories'
import { Menu } from '../components/Menu'
import { HumburgerMenuButton, HumburgerMenu } from '../components/HumburgerMenu'
import { DarkModeSwitchButton } from '../components/DarkModeSwitchButton'
import { Icon } from '../components/Icon'

import useSiteMetadata from '../hooks/useSiteMetadata'
import useTagsList from '../hooks/useTagsList'
import useCategoriesList from '../hooks/useCategoriesList'
import useSearchform from '../hooks/useSearchform'
import useHumburgerMenu from '../hooks/useHumburgerMenu'
import useDarkModeButton from '../hooks/useDarkModeButton'
import useArticleSummaries from '../hooks/useArticleSummaries'

import useAutoPager from '../hooks/useAutoPager'
import useFilteredArticleSummaries from '../hooks/useFilteredArticleSummaries';
//import { Link } from 'gatsby'
/*
const StyledFooter = styled(Footer)`
  	margin-top: 4em;
`
*/
const StickySideHeader = styled.div`
box-sizing: border-box;
    height: 100vh;
   position: sticky;
    top: 0;
    z-index: 10;
  padding: 0;
 //width: 30%;
    @media screen and (max-width: 780px) {
      height: auto;
      padding: 0;
      width: 100%;
    }
`

const StyledSingleArticlePage = styled.div`

 max-width: 66.875rem;
  margin-left: auto;
  margin-right: auto;

 & .page-body {
 	padding: 2em;
 }
	& .page-inner {
		//border-radius: .4em;
		//border: 4px solid var(--text-color);
		//background-color: white;
		
	}

	& .article-summaries {
		display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.4em;
  row-gap: 1.4em;
	}

	& .ads {
		&-single-top {
			margin-bottom: 2em;
		}

		&-single-bottom {
			margin-top: 2em;
		}
	}
	& .container {

//width: 70%;
		padding: 2em 0;
		@media screen and (max-width: 780px) {
				width: 100%;
		}
	}
	& .footer {
		 grid-row: 2 / 3;
    grid-column: 2 / 3;

    & .tags {
			//justify-content: center;
			& .tag {
				background-color: #1a3a8a;
				color: white;
				& .count {
					background-color: #122961;
					color: inherit;
				}
			}
		}
	}
`

const SearchIcon = styled.div`
	padding: 1em;
`

const ArticleSearchform = styled.div`
//background-color: orange;
//border: 2px solid #333;
border-radius: 2em;
display: ${({isShowSearchform}) => isShowSearchform ? 'flex' : 'none'};
//display: flex;
align-items: center;
border: ${({ isFocus }) => isFocus ? `2px solid #e6581c` : `2px solid #333`};
height: 4em;
margin-bottom: 2em;
//transform: ${({isShowSearchform}) => isShowSearchform ? 'translateY(0)' : 'translateY(-4em)'};
//position: relative;
//visibility: ${({isShowSearchform}) => isShowSearchform ? 'visible' : 'hidden'};
transition: .5s all linear;
 //flex-wrap: wrap;
 font-size: .65em;
 width: 100%;

 & input[type="text"]::placeholder {
		   //font-size: 0.75em;
		   color: #555;
    	
    }

 @media screen and (max-width: 780px) {
 	//display: ${({isShowSearchform}) => isShowSearchform ? 'flex' : 'none'};
 	margin-bottom: 0;
 }
	> .search-icon {
		${({ isFocus }) => isFocus && `color: #e6581c;`}
		flex-grow: 1;
		text-align:center;
		transition: .5s color ease;

	}

	> .search {
		background-color: transparent;
		border: none;
		flex-grow: 9;
		

		padding-left: 0;

		&:focus {
		border: none;
			
			outline: 0;
		}
	}
`

const SpHeader = styled.div`
  

  @media screen and (max-width: 780px) {
      display: flex;
    //justify-content: space-between;
    align-items: center;
    width: 100%;
    }

    & .sp-header-items {
    	display: flex;
    	justify-content: center;
    	align-items: center;
    	//margin-left: auto;
    	margin-bottom: 2em;

    	@media screen and (max-width: 780px) {
    		margin-left: auto;
    		margin-bottom: 0;

    		& .searchform-open-button {
    			margin-left: .8em;
	    	}

	    	& .humburger-menu-button {
	    		margin-left: 1.2em;
	    	}

	    	& .dark-mode-switch-button {
	    		margin-right: 0;
	    	}
    	}

    	& .searchform-open-button {
    		margin-left: .5em;
    	}

    	& .dark-mode-switch-button {
    		margin-right: .5em;
    	}
    }
`

const SeachformOpenButton = styled.div`
	//border: 2px solid #333;
	//border-radius: 100%;
	//display: none;
	//display: flex;
	//align-items: center;
	//justify-content: center;
	//margin-bottom: 2em;
	cursor: pointer;
	//font-size: 1.4em;
	//height: 2em;
	//width: 2em;
	@media screen and (max-width: 780px) {
	//	display: block;
	}
`

const SingleArticlePage = props => {
	const { 
    	title,
    	googleAdsense,
    	author,
    	image, 
    	copyright,
    	menu
    } = useSiteMetadata()
    const [textValue, handleChange] = useSearchform()

    const filteredArticleSummaries = useFilteredArticleSummaries(props.pageContext.articleSummaries, textValue)//, 4)
const filteredArticleSummariesPager = useAutoPager(filteredArticleSummaries, 4)

   // const articleSummaries = useArticleSummaries()
console.log(props.pageContext)
//const [toggleMenu, menuElement] = useHumburgerMenu()

    const [isFocus, setIsFocus] = useState(false)
    const [isShowSearchform, setIsShowSearchform] = useState(false)

    const handleFocus = useCallback(e => setIsFocus(true), [])
    const handleBlur = useCallback(e => setIsFocus(false), [])
    const handleClick = useCallback(e => {
    	if(isShowSearchform) {
    		setIsShowSearchform(false)
    	}else{
    		setIsShowSearchform(true)
    	}
    }, [isShowSearchform])

    const tags = useTagsList()

  const categories = useCategoriesList()
const [toggleMenu, isOpen, menuElement] = useHumburgerMenu()

const [
  isDarkMode,
  	checkboxElement,
		handleChangeDarkMode
] = useDarkModeButton()

const articleSummaries = useArticleSummaries()
	return (
		<StyledSingleArticlePage {...props}>
			<Layout className={props.className} layout="row">
				<SEO
					title={props.pageContext.articleData.title}
					description={props.pageContext.articleData.excerpt}
					type="article"
					image={props.pageContext.articleData.headerImage.file.url}
					url={props.pageContext.articleData.pagePath}
				/>			
				<StickySideHeader>
				<Header className="header" isSticky={true}>
					<SpHeader>
<SiteTitle className="site-title" title={title} linkTo="/" logoUrl={isDarkMode ? withPrefix("images/logo_sab_dark.png") : withPrefix("images/logo_sab.png")} />      						<div className="sp-header-items">
						<DarkModeSwitchButton className="dark-mode-switch-button" size=".8em" handleChange={handleChangeDarkMode} ref={checkboxElement} />
						<SeachformOpenButton className="searchform-open-button" onClick={handleClick}>
							<Icon icon={faSearch} size="lg" />
						</SeachformOpenButton>
						<HumburgerMenuButton className="humburger-menu-button" isOpen={isOpen} handleClick={toggleMenu} size=".6em" />
						</div>
					</SpHeader>
					<div style={{ position: 'relative' }}>
					<ArticleSearchform className="searchform" isFocus={isFocus} isShowSearchform={isShowSearchform}>
						<SearchIcon className="search-icon">
							<Icon icon={faSearch} />
						</SearchIcon>
						<InputText
							className="search"
							type="text"
							onChange={handleChange}
							onFocus={handleFocus}
							onBlur={handleBlur}
							value={textValue}
							placeholder="記事を検索"
						/>
					</ArticleSearchform>
					</div>
					<HumburgerMenu isOpen={isOpen}>
						<Categories className="categories" categories={categories} />
						<Menu className="menu" menu={menu} />
					</HumburgerMenu>
					<Contacts className="contacts" contacts={author.contacts} />
					<Copyright className="copyright" copyright={copyright} />
				</Header>
			</StickySideHeader>
				<Page className="container">
						     	<Adsense currentPath={props.pageContext.basePath} className="ads-single-top" client={googleAdsense.client} slot={googleAdsense.slot.singleTop} />
{
					textValue.trim().length > 0 ?
						<>
							<ArticleSummaries
								className="article-summaries"
								articleSummaries={filteredArticleSummariesPager.articleSummaries}
								//skip={filteredArticleSummaries.skip}
								//postsPerPage={4}
							/>
							<AutoPager
								className="more-link"
								limit={filteredArticleSummariesPager.limit}
								handleClick={filteredArticleSummariesPager.seeMore}
							/>
						</>
					: 
						<Article className="article" articleData={props.pageContext.articleData} />
				}


					     		
									<Adsense currentPath={props.pageContext.basePath} className="ads-single-bottom" client={googleAdsense.client} slot={googleAdsense.slot.singleBottom} />

				</Page>
				
			</Layout>
		</StyledSingleArticlePage>
	)
}

export default SingleArticlePage;

/*
<Footer className="footer">
					<Author className="author" author={author} />
					<Tags className="tags" tags={tags} />
				</Footer>




*/