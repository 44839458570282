import { useCallback, useState, useLayoutEffect } from 'react'

const useAutoPager = (articleSummaries, postsPerPage) => {
	const [currentArticleSummaries, setCurrentArticleSummaries] = useState(articleSummaries)
	const [skip, setSkip] = useState(postsPerPage)
	const [limit, setLimit] = useState(null)
	//const [maxPage, setMaxPage] = useState(articleSummaries.length)
	const seeMore = useCallback(e => setSkip(p => p + postsPerPage), [])

	//const resetSkip = useCallback(e => setSkip(postsPerPage), [])

	useLayoutEffect(() => {
		//console.log(articleSummaries.length)
		console.log(skip)
console.log(articleSummaries.length)
		if(skip > articleSummaries.length) {
			setCurrentArticleSummaries(articleSummaries)
			setLimit(0)
			//setCurrentArticleSummaries(articleSummaries)
			return
		}

		setLimit(articleSummaries.length - skip)

		/*
		const newArticleSummaries = []

		for(let i = 0; i < skip; i++) {
			newArticleSummaries.push(articleSummaries[i])
		}*/

//console.log(newArticleSummaries)
		setCurrentArticleSummaries(articleSummaries.slice(0, skip))

	}, [skip, articleSummaries])

	return { articleSummaries: currentArticleSummaries, skip, limit, seeMore }
}

export default useAutoPager