import { useStaticQuery, graphql } from 'gatsby'

const useTagsList = () => {
	const { allContentfulBlogPost } = useStaticQuery(
		graphql`
			query TagsListQuery {
				allContentfulBlogPost {
				    group(field: tags___name) {
				      fieldValue
				      totalCount
				    }
				  }
			}
		`
	)

	const tags = allContentfulBlogPost.group

	return tags
}

export default useTagsList