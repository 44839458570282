import { useState, useEffect } from 'react'
import useAutoPager from './useAutoPager'

const useFilteredArticleSummaries = (articleSummaries, searchWord) => {//, postsPerPage) => {
	const [
		filteredArticleSummaries,
		setFilteredArticleSummaries
	] = useState(articleSummaries)
	
	/*const {
		skip,
		limit,
		seeMore,currentArticleSummaries
	} = useAutoPager(filteredArticleSummaries, postsPerPage)*/


	useEffect(() => {
		/*if(searchWord.trim().length < 1) {
			resetSkip()
		}*/
		//console.log(skip)
		//console.log(limit)

		const f = searchWord ? articleSummaries.filter(a => 
			searchWord.trim().length > 0 &&
			a.title.toLowerCase().includes(searchWord.toLowerCase().trim())
		) : articleSummaries//.slice(0, skip) 

		console.log(f)
		setFilteredArticleSummaries(f)
		
		//setMaxPage(f.length)
	}, [searchWord, articleSummaries])

	return filteredArticleSummaries//, skip, limit, seeMore }
}

export default useFilteredArticleSummaries