import React from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/useSiteMetadata'
import { Button } from './Button'
import { Icon } from './Icon'
import getIcon from '../utils/getIcon'

const StyledHatenaShareButton = styled(Button)`
border: none;
	padding: 0;
	& a {
		background-color: #008fde;
		//border: 2px solid var(--text-color);
	}
`

export const HatenaShareButton = ({ className, articleData }) => {
	const { siteUrl } = useSiteMetadata()

	return (
		<StyledHatenaShareButton className={className}>
			<a href={`http://b.hatena.ne.jp/add?mode=confirm&url=${encodeURIComponent(`${siteUrl}${articleData.pagePath}`)}&title=${articleData.title}
`} rel="noopener noreferrer nofollow" target="_blank">
				<Icon icon={getIcon('hatenabookmark')} />
			</a>
		</StyledHatenaShareButton>
	)
}
