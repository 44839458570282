import React from 'react'
import styled from 'styled-components'

const StyledInputText = styled.input`
		//border: 4px solid var(--text-color);
		border: none;
		//background-color: #f2f2f2;
		 //border-radius: .25em;
		// padding: 1em;
`

const InputText = ({ className, ...props }) => {
	return (
		<StyledInputText 
			className={className}
			type="text"
			{...props}
		/>
	);
}

export default InputText
