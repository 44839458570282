import React, { useEffect } from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/useSiteMetadata'
import { Button } from './Button'
import { Icon } from './Icon'
import getIcon from '../utils/getIcon'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faTwitter } from "@fortawesome/free-brands-svg-icons"

/*
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)
*/
const StyledTwitterShareButton = styled(Button)`
border: none;
	padding: 0;
	& a {
		background-color: #55acee;
		//border: 2px solid var(--text-color);
	}
`

export const TwitterShareButton = ({ className, articleData }) => {
	const { siteUrl, author } = useSiteMetadata()
//useEffect(() => console.log(faTwitter))
	return (
		<StyledTwitterShareButton className={className}>
			<a href={`https://twitter.com/share?
					text=${articleData.title}&
					url=${encodeURIComponent(`${siteUrl}${articleData.pagePath}`)}&
					via=${author.contacts.twitter}&
					related=${author.contacts.twitter}&
					hashtags=${articleData.tags.map(t => t.name)}
					`}
				//rel="nofollow"
				rel="nofollow noopener noreferrer"
				target="_blank"
			>
				<Icon icon={faTwitter} />
			</a>
		</StyledTwitterShareButton>
	)
}
