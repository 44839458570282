import React from 'react'
//import { Link } from 'gatsby'
import styled from 'styled-components'

//import {Tags} from './Tags'
//import useTagsList from '../hooks/useTagsList'

const StyledFooter = styled.footer`
	padding: 2em;
	display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.4em;
  row-gap: 1.4em;
`

export const Footer = ({ className, children }) => {
	//const tags = useTagsList()

	return (
		<StyledFooter className={className}>
			{children}
			
		</StyledFooter>
	)
}

//export default Footer