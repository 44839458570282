import React from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/useSiteMetadata'
import { Button } from './Button'
import { Icon } from './Icon'
import getIcon from '../utils/getIcon'

const StyledFeedlyShareButton = styled(Button)`
//background-color: transparent;
border: none;
	padding: 0;
	& a {
		background-color: #2bb24c;
		//border: 2px solid var(--text-color);
	}
`

export const FeedlyShareButton = ({ className, articleData, ...props }) => {
	const { siteUrl } = useSiteMetadata()

	return (
		<StyledFeedlyShareButton className={className} {...props}>
			<a href={`https://feedly.com/i/subscription/feed/${encodeURIComponent(`${siteUrl}/feed/index.xml`)}`}
				target='blank'
				rel="nofollow"
			>
				<Icon icon={getIcon('feedly')} />
			</a>
		</StyledFeedlyShareButton>
	)
}

