import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'

const StyledButton = styled(Button)`
	//border: 2px solid var(--text-color);
	background-color: #e6d1b5;//var(--bg-color);//orange;//#ff2e5f;
	color: var(--text-color);
	font-size: .8em;
	//font-weight: 900;
`

export const AutoPager = ({ className, limit, handleClick }) => {
	//	console.log(limit)
	return (
		<div css={`text-align: center`}>
			{
		    	limit > 0 ? 
		    		<StyledButton className="more-see-button" onClick={handleClick}>
		    			{`もっと見る（あと${limit}記事）`}
		    		</StyledButton>
		    	: <p>これ以上の記事はありません</p>
		    }
	    </div>
	)
}