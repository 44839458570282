import React from 'react'
import styled from 'styled-components'
import Tag from './Tag'

const StyledTags = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;


	& .tag {
		flex-grow: 1;
		margin-right: .5em;
		margin-top: .5em;
	}

	& .count {
		//border: 1px solid var(--text-color);
		border-radius: .8em;
		color: #333;
		font-size: .6em;
		margin-left: .5em;
		padding: .2em .8em;
	}
`

export const Tags = ({ className, tags }) => (
	<StyledTags className={className}>

		{tags && tags.map(tag => (
			<Tag 
				className={`tag tag-${tag.fieldValue}`}
				name={tag.fieldValue}
				path={`/tag/${encodeURIComponent(tag.fieldValue)}`}
				key={tag.fieldValue}
			>
				<span className="count">{tag.totalCount}</span>
			</Tag>
		))}
	</StyledTags>
)
//export default Tags